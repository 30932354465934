import React from 'react';
import { FaEnvelope, FaDownload } from 'react-icons/fa'; // Import icons
import { CiLocationOn } from "react-icons/ci";

const Header = () => {
  return (
    <div className="flex flex-row items-center justify-center p-2 md:p-4 gap-4 md:gap-6 border-gray-700 border-b w-full max-w-4xl">
  

      <div className="flex flex-col items-center gap-1 text-center p-2">
        <h1 className="text-xl md:text-4xl font-bold">Halil Goksu</h1>
        <h2 className="text-xs md:text-xl font-medium">Mobile App & Software Entrepreneur</h2>
        <p className="text-xs flex p-1 "><CiLocationOn className="mr-2 " /> Colorado</p>
      </div>

      <div className="flex flex-col">
        <button
          onClick={() => window.location.href = 'mailto:halilskywater@gmail.com'}
          className="flex items-center justify-center py-2 px-4 rounded-lg shadow-md mb-4 border border-gray-700 hover:bg-gray-500 transition duration-200"
        >
          <FaEnvelope className="mr-2" /> Email 
        </button>
        <a
          href={require('../assets/cv.pdf')} // Adjusted path
          download="Halil_Goksu_Resume.pdf"
          className="flex items-center justify-center py-2 px-2 md:px-4 rounded-lg shadow-md border border-gray-700 hover:bg-gray-500 transition duration-200"
        >
          <FaDownload className="mr-2" /> Resume
        </a>
      </div>
    </div>
  );
};

export default Header;
