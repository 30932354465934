
const AboutMe = () => {
  return (
    <div className="flex flex-row items-center justify-center  px-6 py-3 border-gray-700 border-b w-full max-w-4xl text-md ">
      <h1 className="">
      Hi, I’m Halil, a Mobile App and Software Entrepreneur with a degree in Public Relations and Advertising. With over three years of experience and my own startup since 2022, I specialize in mobile apps and educational mini-games. 
I’ve developed scalable solutions using modern frameworks, collaborated with cross-functional teams, and ensured optimal performance and scalability in my projects. My expertise includes mobile app development, Firebase integration, and efficient database management.
      </h1>
    </div>
  );
};

export default AboutMe;
